<template>
  <div class="container-fluid position-relative">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center mb-2">
        <h5 class="m-0">วิเคราะห์หวยวันที่</h5>
        <div class="pl-3 pb-1">
          <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
            </div>
            <flat-pickr
              :config="datepickerConfig"
              v-model="date"
              class="form-control form-control-sm"
            />
          </div>
        </div>
        <button class="btn btn-success btn-sm mb-1 ml-2" @click="createResultDate">เพิ่มตามวันที่</button>
      </div>

      <div class="mb-2">
        <button @click="addResult" class="btn btn-info btn-sm mb-1">เพิ่มผลหวย</button>
      </div>
    </div>

    <div class="card border-0">
      <table v-if="groups.length" class="table table-sm table-bordered table-hover mb-0">
        <tbody v-for="group in groups" :key="group.groupId">
          <tr>
            <th colspan="6" class="alert-primary">{{ group.groupTitle }} วันที่ {{ group.date }}</th>
          </tr>
          <tr>
            <th class="text-center" width="100">เวลาออก</th>
            <th class="text-center" width="30%">ชื่อหวย</th>
            <th class="text-center" width="150">3 ตัวบน</th>
            <th class="text-center" width="150">2 ตัวล่าง</th>
            <th class="text-center">วิเคราะห์</th>
            <th width="150"></th>
          </tr>
          <tr v-for="item in group.items" :key="item.marketId">
            <td class="text-center">{{ item.time }}</td>
            <td class="text-center">{{ item.marketTitle }}</td>
            <td class="text-center">{{ item.prizes.threeNumber }}</td>
            <td class="text-center">{{ item.prizes.twoNumber }}</td>
            <td class="text-center">{{ item.predict }}</td>
            <td class="text-center p-0">
              <button class="btn btn-sm btn-link" @click="edit(group, item)">แก้ไข</button>
              <button class="btn btn-sm btn-link text-danger" @click="deleteItem(item)">ลบ</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="!isLoading" class="text-center p-2">ไม่มีข้อมูล</div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />

    <ResultModal :data="resultData" :markets="markets" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import Swal from 'sweetalert2'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import dayjs from '@/helpers/dayjs'

import LottoService from '@/services/lottoService'
import MarketService from '@/services/marketService'

import ResultModal from './components/ResultModal'

export default {
  name: 'Results',
  components: {
    flatPickr,
    Loading,
    ResultModal
  },
  data() {
    return {
      isLoading: false,
      date: this.$route.params?.date || dayjs().format('YYYY-MM-DD'),
      datepickerConfig: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        maxDate: dayjs().add(1, 'd').format("YYYY-MM-DD")
      },
      data: null,
      isShowModal: false,
      resultData: {
        date: '',
        marketId: '',
        marketTitle: '',
        threeNumber: '',
        twoNumber: '',
        predict: '',
        predictThreeNumber: '',
        predictTwoNumber: ''
      },
      markets: []
    }
  },
  computed: {
    groups() {
      return this.data || []
    }
  },
  watch: {
    date(newDate, oldDate) {
      this.getResults()
    }
  },
  methods: {
    getResults() {
      this.isLoading = true
      LottoService.getResults(this.date)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    edit(group, item) {
      this.resultData = {
        date: group.date,
        marketId: item.marketId,
        marketTitle: item.marketTitle,
        threeNumber: item.prizes.threeNumber,
        twoNumber: item.prizes.twoNumber,
        predict: item.predict,
        predictThreeNumber: item.predictNumber.threeNumber,
        predictTwoNumber: item.predictNumber.twoNumber
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.getResults()
      }
    },
    createResultDate() {
      Swal.fire({
        title: 'เพิ่มตารางหวย?',
        text: `วันที่ ${this.date}`,
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.createResultDate(this.date)
          .then((response)=>{
            if(response.success) {
              this.getResults()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: `เพิ่มตารางหวย วันที่ ${this.date} เสร็จเรียบร้อย`,
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'เพิ่มตารางหวยไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
          })
        }
      })
    },
    addResult() {
      if(!this.markets.length) {
        MarketService.getMarkets().then((response)=>{
          if(response.success) {
            this.markets = response.data.filter(m=>m.status==='Active')
          }
        })
      }

      this.resultData = {
        date: this.date,
        marketId: null,
        marketTitle: null,
        threeNumber: '',
        twoNumber: '',
        predict: '',
        predictThreeNumber: '',
        predictTwoNumber: ''
      }
      this.isShowModal = true
    },
    deleteItem(item) {
      Swal.fire({
        title: 'ยืนยันลบผลหวย',
        text: `"${item.marketTitle}" วันที่ ${this.date}`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.deleteResult(item.resultId)
          .then((response)=>{
            if(response.success) {
              this.getResults()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: `ลบผลหวยเสร็จเรียบร้อย`,
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบผลหวยไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
          })
        }
      })
    }
  },
  mounted() {
    this.getResults()
  }
}
</script>
<style lang="scss" scoped>
.table {
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
