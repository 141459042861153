<template>
  <b-modal
    ref="result-modal"
    hide-footer
    :title="modalTitle"
    ok-variant="success"
    :ok-title="modalTitle"
    cancel-title="ยกเลิก"
    @show="setData"
    @hidden="resetModal"
  >
    <form
      id="result-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        v-if="input.marketId"
        label="ชื่อหวย"
      >
        <b-form-input
          v-model="input.marketTitle"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        v-else
        label="ชื่อหวย"
      >
        <b-form-select
          v-model="newMarketId"
          :options="marketOptions"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label="งวดวันที่"
      >
        <b-form-input
          v-model="input.date"
          type="text"
          disabled
        ></b-form-input>
        <!-- <flat-pickr
          :config="datepickerConfig"
          v-model="input.date"
          class="form-control form-control"
        /> -->
      </b-form-group>

      <b-form-group
        label="วิเคราะห์"
      >
        <b-form-input
          v-model="input.predict"
          type="text"
          placeholder="บทวิเคราะห์"
        ></b-form-input>
      </b-form-group>

      <!-- <div class="row">
        <div class="col">
          <b-form-group
            label="วิเคราะห์ 3 ตัวบน"
          >
            <b-form-input
              v-model="input.predictThreeNumber"
              type="text"
              maxLength="3"
              v-on:keydown="keypress"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            label="วิเคราะห์ 2 ตัวล่าง"
          >
            <b-form-input
              v-model="input.predictTwoNumber"
              type="text"
              maxLength="2"
              v-on:keydown="keypress"
            ></b-form-input>
          </b-form-group>
        </div>
      </div> -->
      <b-form-group
        label="วิเคราะห์เลข"
      >
        <b-form-input
          v-model="input.predictTwoNumber"
          type="text"
          placeholder="วิเคราะห์เลข เช่น 23, 56, 87"
          maxLength="2"
          v-on:keydown="keypress"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            label="ผล 3 ตัวบน"
          >
            <b-form-input
              v-model="input.threeNumber"
              type="text"
              maxLength="3"
              v-on:keydown="keypress"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            label="ผล 2 ตัวล่าง"
          >
            <b-form-input
              v-model="input.twoNumber"
              type="text"
              maxLength="2"
              v-on:keydown="keypress"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import LottoService from '@/services/lottoService'
import Swal from 'sweetalert2'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const resultInterface = {
  date: '',
  marketId: '',
  marketTitle: '',
  threeNumber: '',
  twoNumber: '',
  predict: '',
  predictThreeNumber: '',
  predictTwoNumber: ''
}

export default {
  name: 'ResultModal',
  components: {
    flatPickr
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: resultInterface
    },
    markets: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      input: resultInterface,
      isProcess: false,
      needReload: false,
      datepickerConfig: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      },
      newMarketId: null
    }
  },
  computed: {
    modalTitle() {
      return `วิเคราะห์หวย`
    },
    marketOptions() {
      return (this.markets || []).reduce((markets, item)=>{
        markets.push({
          value: item._id,
          text: item.marketTitle
        })
        return markets
      }, [{value: null, text: 'เลือกหวย'}])
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['result-modal'].show()
    },
    hideModal() {
      this.$refs['result-modal'].hide()
    },
    handleSubmit() {

      if(this.input.marketId === null) {
        const market = this.markets.find((m)=>{
          return m._id === this.newMarketId
        })

        if(market) {
          this.input.marketId = market._id
          this.input.marketTitle = market.marketTitle
          this.save()
        }
      }else{
        this.save()
      }
    },
    setData() {
      this.newMarketId = null
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault()
      }
    },
    save() {
      LottoService.saveResult(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
